import store from '@/store'
import { OPEN_DIALOG } from '@/store/actions'

export default function errorHandler(
  error: Array<string>,
  type?: string
) {
  let errorDetails

  if (typeof error === 'string') {
    errorDetails = error
  } else {
    errorDetails = error.join('<br />')
  }

  store.dispatch(OPEN_DIALOG, {
    message: errorDetails,
    type: type || 'error',
  })
}
