import { TYPE_OF_DEVICE_ACTIONS, SET_LOADING, CLEAR_LOADING, OPEN_DIALOG } from './actions'
import { ITypeOfDevice } from '@/utils/types'
import FireTypeOfDevice from '@/services/type-of-devices'

type ITypeOfDeviceState = {
  typeOfDevices: ITypeOfDevice[]
  loading: boolean
}

export default {
  state: {
    typeOfDevices: [],
    loading: false,
  },
  getters: {
    typeOfDevices: (state: ITypeOfDeviceState) => {
      return state.typeOfDevices
    },
  },
  mutations: {
    [TYPE_OF_DEVICE_ACTIONS.SAVE_LIST]: (state: ITypeOfDeviceState, { typeOfDevices }: {
      typeOfDevices: ITypeOfDevice[]
    }) => {
      state.typeOfDevices = typeOfDevices
      state.loading = false
    },
    [TYPE_OF_DEVICE_ACTIONS.CLEAR]: (state: ITypeOfDeviceState) => {
      state.typeOfDevices = []
      state.loading = false
    },
    [SET_LOADING]: (state: ITypeOfDeviceState) => {
      state.loading = true
    },
    [CLEAR_LOADING]: (state: ITypeOfDeviceState) => {
      state.loading = false
    },
  },
  actions: {
    [TYPE_OF_DEVICE_ACTIONS.LOAD_LIST]: async ({ commit, dispatch }: {
      commit: Function
      dispatch: Function
      state: ITypeOfDeviceState
    }) => {
      try {
        commit(SET_LOADING)
        const typeOfDevices = await FireTypeOfDevice.list()
        commit(TYPE_OF_DEVICE_ACTIONS.SAVE_LIST, { typeOfDevices })
      } catch (error) {
        if (error.message) {
          dispatch(OPEN_DIALOG, {
            message: error.message,
            needtranslation: true,
          })
        } else {
          commit(TYPE_OF_DEVICE_ACTIONS.CLEAR)
        }
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
