import firebase from 'firebase'
import moment from 'moment-timezone'

export interface ILoginPayload {
  email: string
  password: string
}

export interface IRegisterPayload {
  email: string
  displayName: string
  password: string
}

// User
export interface IUser {
  id?: string
  staffInfo?: IStaff
  displayName: string
  email: string
  photoURL?: string
  uid?: string
}

export type IFirebaseUser = firebase.User

// Organization
export interface IOrganizationLeaveSetting {
  sendNewLeaveRequestToEmails: string[]
  sendApprovedLeaveRequestEmails: string[]
  sendApprovedOrderDeviceRequestEmails: string[]
}

export interface IOrganization {
  id?: string
  logo?: string
  name?: string
  ownerIds?: string[]
  createdAt?: number // unix timestamp
  updatedAt?: number // unix timestamp

  // Leave setting
  leavesSetting?: IOrganizationLeaveSetting

  fireChangeType?: string // firebase property
}

// Department
export interface IDepartment {
  id?: string
  name?: string
  managerIds?: string[]
  createdAt?: number // unix timestamp
  updatedAt?: number // unix timestamp

  fireChangeType?: string // firebase property
}

// Staff
export interface IStaff {
  id?: string
  avatar?: string
  firstName?: string
  lastName?: string
  birthDate?: number | moment.Moment // unix timestamp
  joinDate?: number | moment.Moment // unix timestamp

  email?: string
  secondEmail?: string
  homeTown?: string
  address?: string
  phone?: string
  note?: string

  departmentIds?: string[]

  createdAt?: number // unix timestamp
  updatedAt?: number // unix timestamp

  fireChangeType?: string // firebase property
}

// Leave
export enum ELeaveType {
  annual = 'annual',
  unpaid = 'unpaid',
}

export enum ELeaveStatus {
  approved = 'approved',
  waiting = 'waiting',
  rejected = 'rejected',
}

export enum ELeaveMorningEvening {
  morning = 'morning',
  evening = 'evening',
  full = 'full',
}

export interface ILeaveDetail {
  fromDate: number | moment.Moment // unix timestamp
  toDate: number | moment.Moment // unix timestamp
  morningEvening: ELeaveMorningEvening
}

export interface ILeave {
  id?: string
  userId?: string

  // Join with IStaff
  staffInfo?: IStaff

  managerIds?: string[]
  status?: ELeaveStatus
  approvedAt?: number // unix timestamp
  approvedId?: string

  leaveType?: ELeaveType

  note?: string

  details?: ILeaveDetail[]
  total?: number

  createdAt?: number // unix timestamp
  updatedAt?: number // unix timestamp

  fireChangeType?: string // firebase property
}

// File uploader
export interface IFileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  type?: string
  size: number
  originFileObj: unknown
}

// Order electronic device
export interface IOrderElectronicDevice {
  id?: string
  deviceName?: string
  typeOfDeviceId?: string
  quantity?: number
  deviceInformation?: string
  user?: string
  isSendMail?: boolean
  expiredAt: number | moment.Moment // unix timestamp
  dateAt: number | moment.Moment// unix timestamp
  createdAt: number // unix timestamp
  updatedAt: number // unix timestamp

  fireChangeType?: string // firebase property
}

// Type of device
export interface ITypeOfDevice {
  id?: string
  name?: string
  createdAt?: number // unix timestamp
  updatedAt?: number // unix timestamp

  fireChangeType?: string // firebase property
}
