<template>
  <SvgBase iconName="IconUser">
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      style="enable-background: new 0 0 32 32"
      xml:space="preserve"
    >
      <rect x="3" y="10" class="st0" :style="{ stroke: color }" width="26" height="16" />
      <circle class="st0" :style="{ stroke: color }" cx="11" cy="16" r="2" />
      <path class="st0" :style="{ stroke: color }" d="M7,22c0-2.2,1.8-4,4-4s4,1.8,4,4" />
      <line class="st0" :style="{ stroke: color }" x1="19" y1="16" x2="25" y2="16" />
      <line class="st0" :style="{ stroke: color }" x1="22" y1="20" x2="25" y2="20" />
      <path class="st0" :style="{ stroke: color }" d="M20,10h-8V9c0-2.2,1.8-4,4-4h0c2.2,0,4,1.8,4,4V10z" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUser extends Vue {}
</script>
<style type="text/css" scoped>
.st0 {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
