<template>
  <div class="notfound-view">
    <h1>404</h1>
    <h2>Not Found</h2>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class NotFound extends Vue {
  @Prop({ type: String }) readonly item: string = 'item'
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 42px;
  margin-bottom: 16px;
}

.notfound-view {
  text-align: center;
  margin-top: 50px;
  img {
    width: 400px;
    max-width: 100%;
  }
}
</style>
