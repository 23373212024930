import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import Antd from 'ant-design-vue'
import 'reset-css'

import './registerServiceWorker'
import App from './App.vue'
import router from './router'
import store from './store'

import moment from 'moment-timezone'
import ja from '@/i18n/ja.json'
import en from '@/i18n/en.json'

import 'firebaseui/dist/firebaseui.css'

const i18n = createI18n({
  locale: navigator.language.toLowerCase(),
  fallbackLocale: 'en',
  messages: {
    ja,
    en,
    'en-us': en,
  },
})

moment.locale(i18n.global.locale)

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Antd)
  .mount('#app')
