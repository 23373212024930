<template>
  <SvgBase iconName="IconLeaves">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
      <g id="IconLeaves" data-name="IconLeaves">
        <rect lass="cls-1" :style="{ stroke: color }" x="1" y="3" width="22" height="20" rx="3" ry="3" />
        <line lass="cls-1" :style="{ stroke: color }" x1="1" y1="9" x2="23" y2="9" />
        <line lass="cls-1" :style="{ stroke: color }" x1="12" y1="5" x2="12" y2="1" />
        <line lass="cls-1" :style="{ stroke: color }" x1="6" y1="5" x2="6" y2="1" />
        <line lass="cls-1" :style="{ stroke: color }" x1="18" y1="5" x2="18" y2="1" />
        <line lass="cls-1" :style="{ stroke: color }" x1="5" y1="14" x2="7" y2="14" />
        <line lass="cls-1" :style="{ stroke: color }" x1="11" y1="14" x2="13" y2="14" />
        <line lass="cls-1" :style="{ stroke: color }" x1="17" y1="14" x2="19" y2="14" />
        <line lass="cls-1" :style="{ stroke: color }" x1="5" y1="18" x2="7" y2="18" />
        <line lass="cls-1" :style="{ stroke: color }" x1="11" y1="18" x2="13" y2="18" />
        <line lass="cls-1" :style="{ stroke: color }" x1="17" y1="18" x2="19" y2="18" />
      </g>
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconLeaves extends Vue {}
</script>
<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
</style>
