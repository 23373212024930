import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NotFound from '@/components/common/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      restricted: true,
      menuKey: 'home',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/organization/setting/:tab?',
    name: 'organization_setting',
    component: () => import(/* webpackChunkName: "organization_setting" */ '../views/OrganizationSetting.vue'),
    meta: {
      restricted: false,
      menuKey: 'organization_setting',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/staffs',
    name: 'staffs',
    component: () => import(/* webpackChunkName: "staffs" */ '../views/StaffList.vue'),
    meta: {
      restricted: false,
      menuKey: 'staffs',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/leaves/:tab?',
    name: 'leaves',
    component: () => import(/* webpackChunkName: "leaves" */ '../views/LeaveList.vue'),
    meta: {
      restricted: false,
      menuKey: 'leaves',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/users/:id',
    name: 'user_profile_view',
    component: () => import(/* webpackChunkName: "user_profile_view" */ '../views/UserProfile.vue'),
    meta: {
      restricted: true,
      menuKey: 'users',
    },
  },
  {
    path: '/profile/edit',
    name: 'user_profile_edit',
    component: () => import(/* webpackChunkName: "user_profile_edit" */ '../views/ProfileEdit.vue'),
    meta: {
      restricted: true,
      menuKey: 'users',
    },
  },
  {
    path: '/order-electronic-device',
    name: 'order_electronic_device',
    component: () => import(/* webpackChunkName: "order_electronic_device" */ '../views/OrderElectronicDevice.vue'),
    meta: {
      restricted: true,
      menuKey: 'order_electronic_device',
      hasLeftSidebar: true,
    },
  },

  {
    path: '/order-electronic-device/:id',
    name: 'order_electronic_device_view',
    component: () => import(/* webpackChunkName: "user_profile_view" */ '../views/OrderElectronicDeviceDetail.vue'),
    meta: {
      restricted: true,
      menuKey: 'order_electronic_device_view',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/type-of-device',
    name: 'type_of_device',
    component: () => import(/* webpackChunkName: "order_electronic_device" */ '../components/type-of-device/TypeOfDeviceList.vue'),
    meta: {
      restricted: true,
      menuKey: 'type_of_device',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
      restricted: true,
      menuKey: 'help',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
    meta: {
      header: {
        logo: false,
        homeButtton: true,
      },
      restricted: false,
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
