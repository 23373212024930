import moment from 'moment-timezone'
import { v5 as uuidv5 } from 'uuid'
import { ELeaveMorningEvening, ILeaveDetail } from './types'

const UUID5_NAME_SPACE = '00000000-0000-0000-0000-000000000000'

export const uuidByString = (str: string) => {
  return uuidv5(str, UUID5_NAME_SPACE)
}

export const formatDate = (dateUnix: number) => {
  if (dateUnix) {
    return moment.unix(dateUnix).format('YYYY/MM/DD')
  }

  return ''
}

export const formatDateTime = (dateUnix: number) => {
  if (dateUnix) {
    return moment.unix(dateUnix).format('YYYY/MM/DD HH:mm')
  }

  return ''
}

export const calculateTotalLeaveByDetail = (details: ILeaveDetail[]) => {
  let total = 0
  for (const detail of details) {
    const duration = moment.duration((detail.toDate as moment.Moment).diff(detail.fromDate))
    total += (duration.asDays() + 1) / (detail.morningEvening !== ELeaveMorningEvening.full ? 2 : 1)
  }

  return total
}
