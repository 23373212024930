
import { Vue, Options } from 'vue-class-component'
import { PROFILE_ACTIONS } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'

import { SettingOutlined, LogoutOutlined, QuestionCircleOutlined, ShoppingOutlined } from '@ant-design/icons-vue'
import { Watch } from 'vue-property-decorator'
import AppLogo from '@/components/common/AppLogo.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import IconUser from '@/components/svg/IconUser.vue'
import IconCompany from '@/components/svg/IconCompany.vue'
import IconDashboard from '@/components/svg/IconDashboard.vue'
import IconLeaves from '@/components/svg/IconLeaves.vue'
import IconShopping from '@/components/svg/IconShopping.vue'
import { IUser } from '@/utils/types'
import { localStore } from '@/utils/localstore'

@Options({
  components: {
    AppLogo,
    UserAvatar,
    IconCompany,
    IconUser,
    IconDashboard,
    IconLeaves,
    IconShopping,
    LogoutOutlined,
    SettingOutlined,
    QuestionCircleOutlined,
    ShoppingOutlined,
  },
  emits: ['update:collapsed'],
})
export default class AppSidebarLeft extends Vue {
  isCollapsed = false
  collapseKey = 'leftmenu-collapse'

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  get isOwner() {
    return this.$store.getters.isOwner
  }

  @Watch('isCollapsed')
  onCollapseChanged() {
    if (this.isCollapsed) {
      localStore.setItem(this.collapseKey, `${this.isCollapsed}`)
    } else {
      localStore.removeItem(this.collapseKey)
    }
  }

  logout() {
    try {
      this.$store.dispatch(PROFILE_ACTIONS.LOGOUT)
      this.goto('login')
    } catch (error) {
      errorHandler([error])
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  created() {
    this.isCollapsed = !!localStore.getItem(this.collapseKey)
  }
}
