<template>
  <div class="app-content" v-if="(alreadyGetProfileOnInit && isValidStaff) || isLoginPage">
    <template v-if="!isLoginPage">
      <AppSidebar />
    </template>

    <!-- Not found -->
    <NotFound v-if="isNotFound" />

    <!-- Login layout -->
    <div v-if="isLoginPage" class="py-10">
      <router-view />
    </div>

    <AppFooter v-if="!isLoginPage" />

    <AppDialog />
  </div>

  <div :class="[isDisplayInvalidStaff ? 'block' : 'hidden']" class="m-10 text-center transition delay-1000">
    <a-alert message="You are not a staff on this oganization. " type="warning" />
    <a-divider />
    <a-button @click="logout">Logout</a-button>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { firebase } from '@/utils/firebase'
import { RESET_PAGE_NOT_FOUND, SET_PAGE_NOT_FOUND, PROFILE_ACTIONS } from '@/store/actions'

import errorHandler from '@/utils/errorHandler'
import { IUser } from '@/utils/types'

import AppSidebar from '@/components/common/AppSidebar.vue'
import AppDialog from '@/components/common/AppDialog.vue'
import NotFound from '@/components/common/NotFound.vue'
import AppFooter from '@/components/common/AppFooter.vue'
import FireUser from './services/users'

@Options({
  components: {
    AppSidebar,
    AppDialog,
    AppFooter,
    NotFound,
  },
})
export default class App extends Vue {
  get alreadyGetProfileOnInit() {
    return this.$store.getters.alreadyGetProfileOnInit
  }

  get isNotFound() {
    return this.$store.state.isNotFound
  }

  get isLoginPage() {
    return this.$route.name === 'login' || this.$route.name === 'register'
  }

  get shouldDisplayRestrictedContent(): boolean {
    return this.isLoginPage || this.isAuth
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get isOwner() {
    return this.$store.getters.isOwner
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isRestrictedPage() {
    return this.$route.meta.restricted
  }

  get isValidStaff() {
    return this.isOwner || this.userInfo?.staffInfo?.id
  }

  get isDisplayInvalidStaff() {
    return this.alreadyGetProfileOnInit && !this.isLoginPage && !this.isValidStaff
  }

  redirectLogin() {
    this.$router.replace({
      name: 'login',
      params: {},
    })
  }

  logout() {
    try {
      this.$store.dispatch(PROFILE_ACTIONS.LOGOUT)
      this.redirectLogin()
    } catch (error) {
      errorHandler([error])
    }
  }

  @Watch('$route.params')
  @Watch('alreadyGetProfileOnInit')
  async onRouterChange() {
    if (!this.isLoginPage) {
      if (this.alreadyGetProfileOnInit && !this.isAuth && this.isRestrictedPage) {
        this.redirectLogin()
      }
    } else if (this.isAuth) {
      this.$router.replace({
        name: 'home',
      })
    }

    if (this.$route.name === 'notFound') {
      this.$store.dispatch(SET_PAGE_NOT_FOUND)
    } else {
      this.$store.dispatch(RESET_PAGE_NOT_FOUND)
    }
  }

  async doLoginSocial(gUser: IUser) {
    const { dispatch } = this.$store

    await dispatch(PROFILE_ACTIONS.LOGIN_FROM_FIREBASE, {
      ...gUser,
    }).catch((error: unknown) => {
      console.log(error)
      errorHandler(['不明なエラーが発生されました。'])
    })
  }

  listenFirebaseAuthChange() {
    if (this.isAuth) {
      return
    }

    firebase.auth().onAuthStateChanged(
      () => {
        const fuser = FireUser.firebaseUser()

        if (fuser?.email) {
          const email = fuser.email || ''
          const user: IUser = {
            email,
            displayName: fuser.displayName || email.split('@')[0],
            photoURL: fuser.photoURL || '',
            uid: fuser.uid,
          }

          this.doLoginSocial(user)
        } else {
          this.$store.dispatch(PROFILE_ACTIONS.SAVE, null)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  mounted() {
    this.listenFirebaseAuthChange()
  }
}
</script>
<style lang="less">
@import '~@/assets/css/mixins.less';
</style>
