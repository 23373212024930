
import { Vue, Options } from 'vue-class-component'
import { RocketOutlined } from '@ant-design/icons-vue'

@Options({
  components: { RocketOutlined },
})
export default class AppLogo extends Vue {
  gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
