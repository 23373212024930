// App
export const OPEN_DIALOG = 'app/openDialog'
export const CLOSE_DIALOG = 'app/closeDialog'
export const SET_PAGE_NOT_FOUND = 'app/setPage404'
export const RESET_PAGE_NOT_FOUND = 'app/resetPage404'
export const SET_LOADING = 'app/setLoading'
export const CLEAR_LOADING = 'app/clearLoading'

// Profile
export const PROFILE_ACTIONS = {
    LOAD: 'PROFILE_ACTIONS/LOAD',
    LOAD_ORGANIZATION: 'PROFILE_ACTIONS/LOAD_ORGANIZATION',
    COMMIT_ORGANIZATION: 'PROFILE_ACTIONS/COMMIT_ORGANIZATION',
    SAVE: 'PROFILE_ACTIONS/SAVE',
    LOGIN: 'PROFILE_ACTIONS/LOGIN',
    LOGIN_FROM_FIREBASE: 'PROFILE_ACTIONS/LOGIN_FROM_FIREBASE',
    LOGOUT: 'PROFILE_ACTIONS/LOGOUT',
    REGISTER: 'PROFILE_ACTIONS/REGISTER',
    PROFILE: 'PROFILE_ACTIONS/PROFILE',
    CLEAR: 'PROFILE_ACTIONS/CLEAR',
}

export const DEPARTMENT_ACTIONS = {
    LOAD_LIST: 'DEPARTMENT_ACTIONS/LOAD_LIST',
    SAVE_LIST: 'DEPARTMENT_ACTIONS/SAVE_LIST',
    CLEAR: 'DEPARTMENT_ACTIONS/CLEAR',
}

export const ORDER_ELECTRONIC_DEVICE_ACTIONS = {
    LOAD_LIST: 'ORDER_ELECTRONIC_DEVICE_ACTIONS/LOAD_LIST',
    SAVE_LIST: 'ORDER_ELECTRONIC_DEVICE_ACTIONS/SAVE_LIST',
    CLEAR: 'ORDER_ELECTRONIC_DEVICE_ACTIONS/CLEAR',
}

export const TYPE_OF_DEVICE_ACTIONS = {
    LOAD_LIST: 'TYPE_OF_DEVICE_ACTIONS/LOAD_LIST',
    SAVE_LIST: 'TYPE_OF_DEVICE_ACTIONS/SAVE_LIST',
    CLEAR: 'TYPE_OF_DEVICE_ACTIONS/CLEAR',
}
