import { FireNorm } from './norm'
import { database } from '@/utils/firebase'
import { IOrderElectronicDevice } from '@/utils/types'
import { COMPANY_ID, FIREBASE_ORGANIZATION_COLLECTION } from '@/utils/constants'

export class FireOrderElectronicDevice extends FireNorm<IOrderElectronicDevice> {
  unsubscribeSnapshot: Function = () => true
  ref = database.collection(FIREBASE_ORGANIZATION_COLLECTION).doc(COMPANY_ID).collection('order_electronic_devices')
}

export default new FireOrderElectronicDevice()
