import { PROFILE_ACTIONS, SET_LOADING, CLEAR_LOADING, OPEN_DIALOG } from './actions'
import { IUser, ILoginPayload, IRegisterPayload, IOrganization } from '@/utils/types'
import FireUser from '@/services/users'
import { firebase } from '@/utils/firebase'
import FireOrganization from '@/services/organization'
import FireStaff from '@/services/staffs'

type TProfileState = {
  user: IUser | null
  organization: IOrganization | null
  alreadyGetProfileOnInit: boolean
  loading: boolean
}

export default {
  state: {
    user: null,
    alreadyGetProfileOnInit: false,
    loading: false,
  },
  getters: {
    isAuth: (state: TProfileState) => {
      return !!state.user
    },
    isOwner: (state: TProfileState) => {
      return state.user?.id && state.organization && state.organization.ownerIds?.includes(state.user.id)
    },
    isLoading: (state: TProfileState) => {
      return state.loading
    },
    userInfo: (state: TProfileState) => {
      return state.user
    },
    alreadyGetProfileOnInit: (state: TProfileState) => {
      return state.alreadyGetProfileOnInit
    },
  },
  mutations: {
    [PROFILE_ACTIONS.SAVE]: (state: TProfileState, { user }: {
      user: IUser
    }) => {
      state.user = user
      state.alreadyGetProfileOnInit = true
      state.loading = false
    },
    [PROFILE_ACTIONS.COMMIT_ORGANIZATION]: (state: TProfileState, { organization }: { organization: IOrganization }) => {
      state.organization = organization
    },
    [PROFILE_ACTIONS.CLEAR]: (state: TProfileState) => {
      state.user = null
      state.loading = false
    },
    [SET_LOADING]: (state: TProfileState) => {
      state.loading = true
    },
    [CLEAR_LOADING]: (state: TProfileState) => {
      state.loading = false
    },
  },
  actions: {
    [PROFILE_ACTIONS.LOAD]: async ({ commit, dispatch, state }: {
      commit: Function
      dispatch: Function
      state: TProfileState
    }) => {
      try {
        if (state.user) {
          return
        }

        const fuser = FireUser.firebaseUser()
        if (fuser?.email) {
          commit(SET_LOADING)
          const userDoc = await FireUser.findUserByEmail(fuser.email)
          const staffInfo = await FireStaff.getByEmail(fuser.email)

          commit(PROFILE_ACTIONS.SAVE, { user: { id: userDoc.id, staffInfo, ...userDoc.data() } })
          dispatch(PROFILE_ACTIONS.LOAD_ORGANIZATION)
        }
      } catch (error) {
        if (error.message) {
          dispatch(OPEN_DIALOG, {
            message: error.message,
            needtranslation: true,
          })

          dispatch(PROFILE_ACTIONS.LOGOUT)
        } else {
          commit(PROFILE_ACTIONS.CLEAR)
        }
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [PROFILE_ACTIONS.LOAD_ORGANIZATION]: async ({ commit }: { commit: Function }) => {
      const organization = await FireOrganization.get()
      commit(PROFILE_ACTIONS.COMMIT_ORGANIZATION, { organization })
    },
    [PROFILE_ACTIONS.SAVE]: async ({ commit }: { commit: Function }, user: IUser | null) => {
      commit(PROFILE_ACTIONS.SAVE, { user })
    },
    [PROFILE_ACTIONS.LOGOUT]: async ({ commit }: { commit: Function }) => {
      commit(PROFILE_ACTIONS.CLEAR)
      firebase.auth().signOut()
    },
    [PROFILE_ACTIONS.REGISTER]: async ({ commit }: { commit: Function }, payload: IRegisterPayload) => {
      try {
        commit(SET_LOADING)
        await FireUser.doRegister(payload)
        await FireUser.signInWithEmailAndPassword(payload.email, payload.password)
      } catch (error) {
        throw error
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [PROFILE_ACTIONS.LOGIN]: async ({ commit }: { commit: Function }, payload: ILoginPayload) => {
      try {
        commit(SET_LOADING)
        await FireUser.signInWithEmailAndPassword(payload.email, payload.password)
      } catch (error) {
        throw error
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [PROFILE_ACTIONS.LOGIN_FROM_FIREBASE]: async ({ commit, dispatch }: { commit: Function, dispatch: Function }, payload: IUser) => {
      try {
        commit(SET_LOADING)
        FireUser.upsertUser(payload).then(() => {
          dispatch(PROFILE_ACTIONS.LOAD)
        })
      } catch (error) {
        throw error
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
