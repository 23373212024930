
import { firebase } from '@/utils/firebase'
import moment from 'moment-timezone'
export interface IDBType {
  id?: string
  createdAt?: number
  updatedAt?: number
  fireChangeType?: string
}

export class FireNorm<T> {
  unsubscribeSnapshot: Function = () => true
  ref!: (
    firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  )

  firebaseUser = () => {
    if (firebase.auth().currentUser?.email) {
      return firebase.auth().currentUser
    }

    return firebase.auth().currentUser?.providerData[0]
  }

  parse = <T>(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>, fireChangeType?: string) => {
    return ({ id: doc.id, fireChangeType, ...doc.data() } as unknown) as T
  }

  nowTimeStamp = () => {
    return Math.round(new Date().getTime() / 1000)
  }

  on = (callback: Function) => {
    this.unsubscribeSnapshot = this.ref.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        callback(this.parse<T>(change.doc, change.type))
      })
    })
  }

  off() {
    this.unsubscribeSnapshot()
  }

  list = async (): Promise<T[]> => {
    const messages: T[] = []

    const docs = (await this.ref.limit(100).get()).docs

    docs.map(doc => {
      messages.push(this.parse<T>(doc))
    })

    return messages
  }

  get = async (id: string) => {
    const doc = await this.ref.doc(id).get()
    return this.parse<T>(doc)
  }

  add = async (data: T & IDBType) => {
    data.createdAt = moment().unix()
    data.updatedAt = moment().unix()
    return this.ref.add({ ...data })
  }

  set = async (id: string, data: T & IDBType) => {
    data.createdAt = moment().unix()
    data.updatedAt = moment().unix()
    this.ref.doc(id).set({ ...data })
  }

  update = async (message: T & IDBType) => {
    message.updatedAt = moment().unix()
    // eslint-disable-next-line
    const { id, fireChangeType, ...updateData } = message

    await this.ref.doc(id).update({
      ...updateData,
    })
  }

  delete = async (id: string) => {
    await this.ref.doc(id).delete()
  }
}
