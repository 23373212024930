<template>
  <a-avatar v-if="userInfo.photoURL" :src="userInfo.photoURL" :size="40" class="border-2 border-solid border-white" />
  <a-avatar
    v-else
    style="color: #f56a00; background-color: #fde3cf"
    :size="40"
    class="border-2 border-solid border-white"
    >{{ user2CharFirst }}</a-avatar
  >
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

import { IUser } from '@/utils/types'

@Options({
  components: {},
})
export default class UserAvatar extends Vue {
  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get user2CharFirst() {
    return this.userInfo.displayName?.charAt(0)?.toUpperCase() || 'U'
  }
}
</script>
