import moment from 'moment-timezone'
import { FireNorm } from './norm'
import { database } from '@/utils/firebase'
import { IOrganization } from '@/utils/types'
import { COMPANY_ID, FIREBASE_ORGANIZATION_COLLECTION } from '@/utils/constants'

export class FireOrganization extends FireNorm<IOrganization> {
  ref = database.collection(FIREBASE_ORGANIZATION_COLLECTION)
  doc = this.ref.doc(COMPANY_ID)

  get = async () => {
    const doc = await this.doc.get()
    return this.parse<IOrganization>(doc)
  }

  isExisting = async () => {
    return (await this.doc.get()).exists
  }

  save = async (message: IOrganization) => {
    message.updatedAt = moment().unix()
    // eslint-disable-next-line
    const { id, fireChangeType, ...updateData } = message

    const isExisting = await this.isExisting()
    if (isExisting) {
      await this.doc.update({
        ...updateData,
      })
    } else {
      await this.doc.set({
        ...updateData,
      })
    }
  }
}

export default new FireOrganization()
