import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyDEHbYVutTM_Z5DzCY2F1Ff5zs9L13RqBc',
  authDomain: 'sango-tasks-dev.firebaseapp.com',
  projectId: 'sango-tasks-dev',
  storageBucket: 'sango-tasks-dev.appspot.com',
  messagingSenderId: '48653907066',
  appId: '1:48653907066:web:cde5f92cb1cb0bd5aef7c3',
  measurementId: 'G-BVFJ0NMQGD',
}

firebase.initializeApp(firebaseConfig)

const database = firebase.firestore()
database.enablePersistence()

const storageRef = firebase.storage().ref()

export {
  firebase,
  database,
  storageRef,
}
