<template>
  <a class="app-layout__logo block truncate" @click.prevent="gotoHome">
    <RocketOutlined class="text-xl" />
    <span class="app-layout__logo-text text-lg ml-2"> HR </span>
  </a>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { RocketOutlined } from '@ant-design/icons-vue'

@Options({
  components: { RocketOutlined },
})
export default class AppLogo extends Vue {
  gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
</script>
