import { DEPARTMENT_ACTIONS, SET_LOADING, CLEAR_LOADING, OPEN_DIALOG } from './actions'
import { IDepartment } from '@/utils/types'
import FireDepartment from '@/services/departments'

type IDepartmentState = {
  departments: IDepartment[]
  loading: boolean
}

export default {
  state: {
    departments: [],
    loading: false,
  },
  getters: {
    departments: (state: IDepartmentState) => {
      return state.departments
    },
  },
  mutations: {
    [DEPARTMENT_ACTIONS.SAVE_LIST]: (state: IDepartmentState, { departments }: {
      departments: IDepartment[]
    }) => {
      state.departments = departments
      state.loading = false
    },
    [DEPARTMENT_ACTIONS.CLEAR]: (state: IDepartmentState) => {
      state.departments = []
      state.loading = false
    },
    [SET_LOADING]: (state: IDepartmentState) => {
      state.loading = true
    },
    [CLEAR_LOADING]: (state: IDepartmentState) => {
      state.loading = false
    },
  },
  actions: {
    [DEPARTMENT_ACTIONS.LOAD_LIST]: async ({ commit, dispatch }: {
      commit: Function
      dispatch: Function
      state: IDepartmentState
    }) => {
      try {
        commit(SET_LOADING)
        const departments = await FireDepartment.list()
        commit(DEPARTMENT_ACTIONS.SAVE_LIST, { departments })
      } catch (error) {
        if (error.message) {
          dispatch(OPEN_DIALOG, {
            message: error.message,
            needtranslation: true,
          })
        } else {
          commit(DEPARTMENT_ACTIONS.CLEAR)
        }
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
