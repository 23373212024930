import { FireNorm } from './norm'
import { database } from '@/utils/firebase'
import { IDepartment } from '@/utils/types'
import { COMPANY_ID, FIREBASE_ORGANIZATION_COLLECTION } from '@/utils/constants'

export class FireDepartment extends FireNorm<IDepartment> {
  ref = database.collection(FIREBASE_ORGANIZATION_COLLECTION).doc(COMPANY_ID).collection('departments')
}

export default new FireDepartment()
