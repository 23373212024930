<template>
  <div class="app-layout">
    <div class="flex">
      <AppSidebarLeft v-if="displayLeftSidebar" />
      <div class="flex-grow">
        <!-- Header responsive -->
        <a-layout-header class="px-4 app-layout__header gap-2 md:hidden block">
          <div class="flex items-center gap-4 w-full">
            <AppSidebarMobile />
            <div class="flex-grow text-center">
              <AppLogo />
            </div>
          </div>
        </a-layout-header>

        <a-layout-content class="min-h-screen">
          <router-view />
        </a-layout-content>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

import { IUser } from '@/utils/types'
import AppSidebarLeft from '@/components/common/AppSidebarLeft.vue'
import AppSidebarMobile from '@/components/common/AppSidebarMobile.vue'

import { UserOutlined, PlusOutlined, MenuOutlined, LoginOutlined, DashboardOutlined } from '@ant-design/icons-vue'

import AppLogo from '@/components/common/AppLogo.vue'

@Options({
  components: {
    AppSidebarLeft,
    AppSidebarMobile,
    AppLogo,
    DashboardOutlined,
    LoginOutlined,
    MenuOutlined,
    PlusOutlined,
    UserOutlined,
  },
  emits: ['update:collapsed'],
})
export default class AppSidebar extends Vue {
  searchQuery = ''

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isAuth(): IUser {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  get displayLeftSidebar() {
    return this.$route.meta?.hasLeftSidebar
  }

  gotoUserProfile() {
    this.goto('user_profile_view', { id: this.userInfo.id })
  }

  gotoEditProfile() {
    this.goto('user_profile_edit')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
</script>
