import { FireNorm } from './norm'
import { database } from '@/utils/firebase'
import { IStaff } from '@/utils/types'
import { COMPANY_ID, FIREBASE_ORGANIZATION_COLLECTION } from '@/utils/constants'
import FireUser from './users'

export class FireStaff extends FireNorm<IStaff> {
  unsubscribeSnapshot: Function = () => true

  ref = database.collection(FIREBASE_ORGANIZATION_COLLECTION).doc(COMPANY_ID).collection('staffs')

  async getStaffByUserId(userId: string) {
    const user = await FireUser.get(userId)
    if (user) {
      return await this.getByEmail(user.email)
    }
  }

  async getByEmail(email: string): Promise<IStaff | null> {
    const docs = (await this.ref.where('email', '==', email).get()).docs
    if (docs.length) {
      return this.parse(docs[0])
    }

    return null
  }
}

export default new FireStaff()
