
import { Vue, Options } from 'vue-class-component'

import { IUser } from '@/utils/types'

@Options({
  components: {},
})
export default class UserAvatar extends Vue {
  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get user2CharFirst() {
    return this.userInfo.displayName?.charAt(0)?.toUpperCase() || 'U'
  }
}
