import { FireNorm } from './norm'
import { database } from '@/utils/firebase'
import { ITypeOfDevice } from '@/utils/types'
import { COMPANY_ID, FIREBASE_ORGANIZATION_COLLECTION } from '@/utils/constants'

export class FireTypeOfDevice extends FireNorm<ITypeOfDevice> {
  ref = database.collection(FIREBASE_ORGANIZATION_COLLECTION).doc(COMPANY_ID).collection('type_of_devices')
}

export default new FireTypeOfDevice()
