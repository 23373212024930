import { firebase, database } from '@/utils/firebase'
import { uuidByString } from '@/utils/helpers'
import { IRegisterPayload, IUser } from '@/utils/types'
import { FireNorm } from './norm'

export class FireUser extends FireNorm<IUser> {
  ref = database.collection('users')

  upsertUser = async (user: IUser) => {
    if (!user.email) {
      throw new Error('upsertUser: Unknow user email')
    }

    const existingRecord = await this.findUserByEmail(user.email)
    if (!existingRecord?.exists) {
      const userId = uuidByString(user.email)
      return this.ref.doc(userId).set({ ...user })
    } else {
      // FireUser.ref.doc(existingRecord.id).update(user)
    }
  }

  findUserByEmail = async (email: string): Promise<firebase.firestore.DocumentData> => {
    if (!email) {
      return Promise.reject(new Error('Unknow email'))
    }

    const userId = uuidByString(email)
    return await this.ref.doc(userId).get()
  }

  doRegister = async (userRegister: IRegisterPayload) => {
    const register = await firebase.auth().createUserWithEmailAndPassword(userRegister.email, userRegister.password)
    if (register?.user) {
      return this.upsertUser({ displayName: userRegister.displayName, email: userRegister.email })
    }
  }

  signInWithEmailAndPassword = (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }
}

const fireUser = new FireUser()
export default fireUser
