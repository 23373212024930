
import { Vue, Options } from 'vue-class-component'

import { CLOSE_DIALOG } from '@/store/actions'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class AppDialog extends Vue {
  get visible() {
    return this.$store.state.dialogVisible
  }

  get config() {
    return this.$store.state.dialogConfig
  }

  get message() {
    return this.config.message
  }

  closeDialog() {
    this.$store.dispatch(CLOSE_DIALOG)
  }

  @Watch('visible')
  visibleChange(visible: boolean) {
    if (visible) {
      switch (this.config.type) {
        case 'error': {
          this.$message.error(this.message, 1, () => {
            this.closeDialog()
          })
          break
        }

        default: {
          this.$message.info(this.message, 1, () => {
            this.closeDialog()
          })
          break
        }
      }
    }
  }
}
