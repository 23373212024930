import { ORDER_ELECTRONIC_DEVICE_ACTIONS, SET_LOADING, CLEAR_LOADING, OPEN_DIALOG } from './actions'
import { IOrderElectronicDevice } from '@/utils/types'
import FireOrderElectronicDevice from '@/services/order-electronic-devices'

type IOrderElectronicDeviceState = {
  orderElectronicDevices: IOrderElectronicDevice[]
  loading: boolean
}

export default {
  state: {
    orderElectronicDevices: [],
    loading: false,
  },
  getters: {
    orderElectronicDevices: (state: IOrderElectronicDeviceState) => {
      return state.orderElectronicDevices
    },
  },
  mutations: {
    [ORDER_ELECTRONIC_DEVICE_ACTIONS.SAVE_LIST]: (state: IOrderElectronicDeviceState, { orderElectronicDevices }: {
      orderElectronicDevices: IOrderElectronicDevice[]
    }) => {
      state.orderElectronicDevices = orderElectronicDevices
      state.loading = false
    },
    [ORDER_ELECTRONIC_DEVICE_ACTIONS.CLEAR]: (state: IOrderElectronicDeviceState) => {
      state.orderElectronicDevices = []
      state.loading = false
    },
    [SET_LOADING]: (state: IOrderElectronicDeviceState) => {
      state.loading = true
    },
    [CLEAR_LOADING]: (state: IOrderElectronicDeviceState) => {
      state.loading = false
    },
  },
  actions: {
    [ORDER_ELECTRONIC_DEVICE_ACTIONS.LOAD_LIST]: async ({ commit, dispatch }: {
      commit: Function
      dispatch: Function
      state: IOrderElectronicDeviceState
    }) => {
      try {
        commit(SET_LOADING)
        const orderElectronicDevices = await FireOrderElectronicDevice.list()
        commit(ORDER_ELECTRONIC_DEVICE_ACTIONS.SAVE_LIST, { orderElectronicDevices })
      } catch (error) {
        if (error.message) {
          dispatch(OPEN_DIALOG, {
            message: error.message,
            needtranslation: true,
          })
        } else {
          commit(ORDER_ELECTRONIC_DEVICE_ACTIONS.CLEAR)
        }
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
